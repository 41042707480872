<template>
    <div class="error-loading">
        <van-loading type="spinner" color="#1989fa" vertical>尚未发布，敬请期待</van-loading>
    </div>
</template>

<script>
    export default {
        name: "error"
    }
</script>

<style scoped>
    .error-loading {
        position: fixed;
        top: 50%;
        margin-top: -30px;
        width: 100%;
        height: 60px;
        text-align: center;
    }
</style>
